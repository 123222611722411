<template>
  <div>
    <v-select
      v-model="project"
      :clearable="false"
      :options="projectOptions"
    />
  </div>
</template>

<script>
import { isEqual, cloneDeep } from 'lodash'
import vSelect from 'vue-select'
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    vSelect,
  },
  props: {
    projectOptions: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      project: null,
    }
  },
  computed: {
    out() {
      return cloneDeep(this.project)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.project = cloneDeep(this.value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
