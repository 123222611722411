<template>
  <b-card no-body>
    <b-card-header
      header-class="p-1"
      role="button"
      @click="expanded = !expanded"
    >
      <div>
        <h3 class="my-0">
          {{ title }}
        </h3>
      </div>

      <b-button
        v-if="sortBy"
        size="sm"
        @click.stop="sortItems"
      >
        Sort by {{ sortBy }}
      </b-button>
    </b-card-header>

    <b-card-body
      v-show="expanded"
      class="table-card-body"
    >
      <div>
        <div
          v-for="(item, index) of items"
          :key="index"
          class="field-row d-flex"
        >

          <div>
            {{ index + 1 }}
          </div>

          <div
            v-for="field of fields"
            :key="field.key"
            :class="{
              'field-wrapper': field.type !== 'checkbox',
              'field-wrapper-checkbox': field.type === 'checkbox'
            }"
          >
            <b-form-group
              v-if="field.type === 'checkbox'"
              :label="field.key"
            >
              <b-form-checkbox
                v-model="items[index][field.key]"
                :value="true"
                :unchecked-value="false"
                :inline="field.key === 'export'"
                :switch="field.key === 'export'"
              />
            </b-form-group>

            <b-form-group
              v-if="field.type === 'text'"
              :label="field.key"
            >
              <div class="position-relative flex-grow-1">
                <b-form-input
                  :value="items[index][field.key]"
                  :placeholder="field.key"
                  type="text"
                  class="input-with-spinner"
                  @input="(e) => onInput(e, index, field.key)"
                  @focus="() => onFocus(index, items[index][field.key])"
                />
                <b-spinner
                  v-if="index === focusedIndex && valueKey === field.key && debounceTimeout"
                  small
                  class="position-absolute input-loader"
                  label="Input Loader"
                />
              </div>
              <p
                v-if="valueKey === field.key && hashItems[items[index][field.key]] > 1"
                class="is-duplicate"
              >
                This key already exist
              </p>
            </b-form-group>

            <b-form-group
              v-if="field.type === 'select'"
              :label="field.key"
            >
              <v-select
                v-model="items[index][field.key]"
                :label="options[field.optionsId].lableKey"
                :options="options[field.optionsId].items"
                :reduce="option => option[options[field.optionsId].valueKey]"
              />
            </b-form-group>

            <b-form-group
              v-if="field.type === 'qualifier'"
              :label="field.key"
            >
              <v-select
                v-model="items[index][field.key]"
                :options="qualifierOptions"
              />
            </b-form-group>
            <b-form-group
              v-if="field.type === 'compoundKeys'"
              :label="field.key"
            >
              <v-select
                v-model="items[index][field.key]"
                :options="compoundKeyOptions"
              />
            </b-form-group>

          </div>
          <div>
            <feather-icon
              icon="Trash2Icon"
              class="delete-btn cursor-pointer mx-auto"
              size="20"
              @click="deleteItem(index)"
            />
          </div>
        </div>

        <div class="add-item-row d-flex">
          <div class="add-item-btn">
            <add-item
              :label="title"
              @add="addItems"
            />
          </div>
          <div>
            <save-button action="definitionSettings/saveData" />
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BSpinner, BCardHeader, BCardBody, BFormInput, BButton, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { isEqual, cloneDeep } from 'lodash'
import AddItem from '@/components/UI/AddItem.vue'
import SaveButton from '@/components/UI/SaveButton.vue'

export default {
  components: {
    BCard,
    BSpinner,
    BCardHeader,
    BCardBody,
    BButton,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    AddItem,
    vSelect,
    SaveButton,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      required: false,
      default() {
        return null
      },
    },
    valueKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
      items: [],
      hashItems: {},
      textInput: '',
      debounceTimeout: null,
      focusedIndex: null,
    }
  },
  computed: {
    options() {
      return this.$store.getters['definitionSettings/options']
    },
    out() {
      return cloneDeep(this.items)
    },
    keyQualifiers() {
      return this.$store.getters['definitionSettings/keyQualifiers']
    },
    qualifierOptions() {
      return this.keyQualifiers.map(qualifier => qualifier.name)
    },
    compoundKeys() {
      return this.$store.getters['definitionSettings/compoundKeys']
    },
    compoundKeyOptions() {
      return this.compoundKeys.map(compoundKey => compoundKey.name)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
    expanded(newVal) {
      if (newVal) {
        this.generateHashTable()
      }
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      const items = this.value.map(record => {
        const item = {}
        this.fields.forEach(field => {
          let fieldValue
          if (field.type === 'checkbox') {
            if (field.key === 'export' && typeof record.export === 'undefined') {
              fieldValue = true
            } else {
              fieldValue = !!record[field.key]
            }
          } else {
            fieldValue = String(record[field.key] || '')
          }

          item[field.key] = fieldValue
        })
        return item
      })
      this.items = items
    },
    addItems(count) {
      const cols = []

      for (let i = 0; i < count; i += 1) {
        const col = {}

        this.fields.forEach(field => {
          let fieldValue

          if (field.type === 'checkbox') {
            fieldValue = field.key === 'export'
          } else {
            fieldValue = ''
          }

          col[field.key] = fieldValue
        })

        cols.push(col)
      }

      this.items = this.items.concat(cols)
    },
    deleteItem(index) {
      this.syncHashItems(index)

      this.items.splice(index, 1)

      this.focusedIndex = null
    },
    sortItems() {
      this.items = cloneDeep(this.items).sort((a, b) => {
        const valueA = a[this.sortBy].toUpperCase()
        const valueB = b[this.sortBy].toUpperCase()

        if (valueA < valueB) { return -1 }
        if (valueA > valueB) { return 1 }
        return 0
      })
    },
    onFocus(index, value) {
      this.focusedIndex = index
      this.textInput = value
    },
    onInput(newKeyValue, index, keyField) {
      this.textInput = newKeyValue

      clearTimeout(this.debounceTimeout)

      this.debounceTimeout = setTimeout(() => {
        const oldKeyValue = this.items[index][keyField]
        if (this.valueKey === keyField) {
          this.checkDuplicate(oldKeyValue, newKeyValue, index)
        }

        this.items[index][keyField] = this.textInput
        this.debounceTimeout = null
      }, 100)
    },
    generateHashTable() {
      const hashItems = {}

      this.items.forEach(item => {
        if (!item[this.valueKey]) {
          return
        }

        if (!hashItems[item[this.valueKey]]) {
          hashItems[item[this.valueKey]] = 1

          return
        }

        hashItems[item[this.valueKey]] += 1
      })

      this.hashItems = hashItems
    },
    checkDuplicate(oldKeyValue, newKeyValue) {
      const hashItems = { ...this.hashItems }

      // Add newKey or increase newKey count to hashItems
      if (!hashItems[newKeyValue]) {
        if (newKeyValue) {
          hashItems[newKeyValue] = 1
        }
      } else {
        hashItems[newKeyValue] += 1
      }

      this.hashItems = this.reduceHashItems(hashItems, oldKeyValue)
    },
    syncHashItems(index) {
      const hashItems = { ...this.hashItems }
      const hashKey = this.items[index][this.valueKey]

      this.hashItems = this.reduceHashItems(hashItems, hashKey)
    },
    reduceHashItems(hashItemsParam, hashKey) {
      const hashItems = { ...hashItemsParam }

      // reduce hashKey count from hashItems if exist
      if (hashItems[hashKey]) {
        hashItems[hashKey] -= 1
      }

      // remove hashKey from hashItems if needed
      if (hashItems[hashKey] === 0) {
        delete hashItems[hashKey]
      }

      return hashItems
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.table-card-body {
  padding-top: 1.5rem !important;
}
.field-row {
  column-gap: 10px;
}
.field-wrapper {
  flex-basis: 300px;
}
.field-wrapper-checkbox {
  flex-basis: 150px;
}
.delete-btn {
  margin-top: 35px
}
.add-item-row {
  column-gap: 10px;
}
.add-item-btn {
  flex-basis:250px;
}
.is-duplicate {
  margin-top: 2px;
  color: #d6604f;
  font-weight: 500;
}

.input-with-spinner {
  padding-right: 30px;
}

.input-loader {
  top: 30%;
  right: 10px;
}

</style>
