<template>
  <div>
    <div class="d-flex align-items-center justify-content-center mb-1">
      <h2 class="my-0 flex-grow-1">
        Key Qualifiers
      </h2>
      <div>
        <b-button
          class="mx-1"
          variant="outline-primary"
          @click="addQualifier"
        >
          Add Qualifier
        </b-button>
        <save-button action="definitionSettings/saveData" />
      </div>
    </div>
    <div>
      <key-qualifier
        v-for="(qualifier, index) of qualifiers"
        :id="index + 1"
        :key="index"
        v-model="qualifiers[index]"
        :expanded="expands[index]"
        @delete="deleteQualifier(index)"
        @toggle-expand="toggleExpand(index)"
      />
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import SaveButton from '@/components/UI/SaveButton.vue'
import KeyQualifier from './KeyQualifier.vue'

export default {
  components: {
    BButton,
    KeyQualifier,
    SaveButton,
  },
  data() {
    return {
      expands: [],
    }
  },
  computed: {
    qualifiers: {
      get() {
        return this.$store.getters['definitionSettings/keyQualifiers']
      },
      set(value) {
        this.$store.commit('definitionSettings/SET_KEY_QUALIFIERS', value)
      },
    },
  },
  created() {
    this.setInitialExpandStatus()
  },
  methods: {
    setInitialExpandStatus() {
      this.expands = this.qualifiers.map(() => false)
    },
    toggleExpand(index) {
      this.$set(this.expands, index, !this.expands[index])
    },
    addQualifier() {
      this.qualifiers.push({
        name: '',
        options: [],
      })
      this.expands.push(false)
    },
    deleteQualifier(index) {
      this.qualifiers.splice(index, 1)
      this.expands.splice(index, 1)
    },
  },
}
</script>
