<template>
  <b-card no-body>
    <b-card-header
      header-class="p-1"
      role="button"
      @click="$emit('toggle-expand')"
    >
      <h3 class="my-0">
        {{ title }}
      </h3>
      <div>
        <feather-icon
          v-b-tooltip.hover
          title="Delete Compound Key"
          icon="Trash2Icon"
          class="cursor-pointer"
          size="22"
          @click.stop="$emit('delete')"
        />
      </div>
    </b-card-header>

    <b-card-body
      v-show="expanded"
    >
      <b-row>
        <b-col lg="2">
          <b-form-group
            label="Name"
          >
            <b-form-input
              v-model="compoundKey.name"
              type="text"
              placeholder="Name"
            /></b-form-group>
        </b-col>
      </b-row>

      <label
        class="mb-b"
        style="font-size:inherit;"
      >Keys</label>
      <b-row>
        <b-col
          v-for="(compoundKeyOptions, index) of compoundKey.keyItems"
          :key="index"
          lg="12"
        >
          <div
            class="fields-row d-flex align-items-center"
          >
            <div>
              {{ index + 1 }}
            </div>

            <div class="field-wrapper">
              <b-form-group
                label="keyLabel"
              >
                <b-form-input
                  v-model="compoundKey.keyItems[index].keyLabel"
                  type="text"
                  placeholder="keyLabel"
                />
              </b-form-group>
            </div>
            <div class="field-wrapper">
              <b-form-group
                label="keyValue"
              >
                <div class="position-relative flex-grow-1">
                  <b-form-input
                    :value="compoundKey.keyItems[index].keyValue"
                    type="text"
                    placeholder="keyValue"
                    class="input-with-spinner"
                    @input="(e) => onInput(e, index)"
                    @focus="() => onFocus(index, compoundKey.keyItems[index].keyValue)"
                  />
                  <b-spinner
                    v-if="index === focusedIndex && debounceTimeout"
                    small
                    class="position-absolute input-loader"
                    label="Input Loader"
                  />
                </div>
                <p
                  v-if="hashItems[compoundKey.keyItems[index].keyValue] > 1"
                  class="is-duplicate"
                >
                  This key already exist
                </p>
              </b-form-group>
            </div>

            <div class="field-wrapper">
              <b-form-group
                label="type"
              >
                <v-select
                  v-model="compoundKey.keyItems[index].type"
                  :label="options['options-col-type'].lableKey"
                  :options="options['options-col-type'].items"
                  :reduce="option => option[options['options-col-type'].valueKey]"
                />
              </b-form-group>
            </div>

            <div class="field-wrapper">
              <b-form-group
                label="qualifier"
              >
                <v-select
                  v-model="compoundKey.keyItems[index].qualifier"
                  :options="qualifierOptions"
                />
              </b-form-group>
            </div>

            <div class="field-wrapper">
              <b-form-group
                label="modType"
              >
                <v-select
                  v-model="compoundKey.keyItems[index].modType"
                  :label="options['options-col-modType'].lableKey"
                  :options="options['options-col-modType'].items"
                  :reduce="option => option[options['options-col-modType'].valueKey]"
                />
              </b-form-group>
            </div>

            <feather-icon
              v-b-tooltip.hover
              title="Delete Key"
              icon="Trash2Icon"
              class="cursor-pointer ml-1"
              size="22"
              @click.stop="deleteOption(index)"
            />
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col lg="2">
          <add-item
            label="Key"
            @add="addKeyItem"
          />
        </b-col>
        <save-button action="definitionSettings/saveData" />
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

import {
  BCard, BSpinner, BCardHeader, BCardBody, VBTooltip, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { isEqual, cloneDeep } from 'lodash'
import vSelect from 'vue-select'

import AddItem from '@/components/UI/AddItem.vue'
import SaveButton from '@/components/UI/SaveButton.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BSpinner,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    AddItem,
    SaveButton,
    vSelect,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      compoundKey: {},
      hashItems: {},
      textInput: '',
      debounceTimeout: null,
      focusedIndex: null,
    }
  },
  computed: {
    title() {
      return this.compoundKey.name ? this.compoundKey.name : `Compound Key ${this.id}`
    },
    options() {
      return this.$store.getters['definitionSettings/options']
    },
    out() {
      return cloneDeep(this.compoundKey)
    },
    keyQualifiers() {
      return this.$store.getters['definitionSettings/keyQualifiers']
    },
    qualifierOptions() {
      return this.keyQualifiers.map(qualifier => qualifier.name)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.keyValue)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
    expanded(newVal) {
      if (newVal) {
        this.generateHashTable()
      }
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.compoundKey = cloneDeep(this.value)
    },
    addKeyItem(count) {
      const keyItems = []
      for (let i = 0; i < count; i += 1) {
        keyItems.push({
          keyLabel: '',
          keyValue: '',
          type: '',
          qualifier: '',
          modType: '',
        })
      }
      this.compoundKey.keyItems = this.compoundKey.keyItems.concat(keyItems)
    },
    deleteOption(index) {
      this.syncHashItems(index)

      this.compoundKey.keyItems.splice(index, 1)

      this.focusedIndex = null
    },
    onFocus(index, value) {
      this.focusedIndex = index
      this.textInput = value
    },
    onInput(newKeyValue, index) {
      this.textInput = newKeyValue

      clearTimeout(this.debounceTimeout)

      this.debounceTimeout = setTimeout(() => {
        const oldKeyValue = this.compoundKey.keyItems[index].keyValue

        this.checkDuplicate(oldKeyValue, newKeyValue, index)

        this.compoundKey.keyItems[index].keyValue = this.textInput
        this.debounceTimeout = null
      }, 100)
    },
    generateHashTable() {
      const hashItems = {}

      this.compoundKey.keyItems.forEach(item => {
        if (!item.keyValue) {
          return
        }

        if (!hashItems[item.keyValue]) {
          hashItems[item.keyValue] = 1

          return
        }

        hashItems[item.keyValue] += 1
      })

      this.hashItems = hashItems
    },
    checkDuplicate(oldKeyValue, newKeyValue) {
      const hashItems = { ...this.hashItems }

      // Add newKey or increase newKey count to hashItems
      if (!hashItems[newKeyValue]) {
        if (newKeyValue) {
          hashItems[newKeyValue] = 1
        }
      } else {
        hashItems[newKeyValue] += 1
      }

      this.hashItems = this.reduceHashItems(hashItems, oldKeyValue)
    },
    syncHashItems(index) {
      const hashItems = { ...this.hashItems }
      const hashKey = this.compoundKey.keyItems[index].keyValue

      this.hashItems = this.reduceHashItems(hashItems, hashKey)
    },
    reduceHashItems(hashItemsParam, hashKey) {
      const hashItems = { ...hashItemsParam }

      // reduce hashKey count from hashItems if exist
      if (hashItems[hashKey]) {
        hashItems[hashKey] -= 1
      }

      // remove hashKey from hashItems if needed
      if (hashItems[hashKey] === 0) {
        delete hashItems[hashKey]
      }

      return hashItems
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.fields-row {
  column-gap: 1rem;
}
.field-wrapper {
  flex-basis: 300px;
}
.isDuplicate{
  color: #d6604f;
  font-weight: 500;
}
.input-with-spinner {
  padding-right: 30px;
}
.is-duplicate {
  margin-top: 2px;
  color: #d6604f;
  font-weight: 500;
}
.input-loader {
  top: 30%;
  right: 10px;
}
</style>
