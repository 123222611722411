<template>
  <div>
    <div class="d-flex align-items-center justify-content-center mb-1">
      <h2 class="my-0 flex-grow-1">
        Compound Keys
      </h2>
      <div>
        <b-button
          class="mx-1"
          variant="outline-primary"
          @click="addCompoundKey"
        >
          Add Compound Key
        </b-button>
        <save-button action="definitionSettings/saveData" />
      </div>
    </div>
    <div>
      <compound-key
        v-for="(compoundKey, index) of compoundKeys"
        :id="index + 1"
        :key="index"
        v-model="compoundKeys[index]"
        :expanded="expands[index]"
        @delete="deleteCompoundKey(index)"
        @toggle-expand="toggleExpand(index)"
      />
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import SaveButton from '@/components/UI/SaveButton.vue'
import CompoundKey from './CompoundKey.vue'

export default {
  components: {
    BButton,
    CompoundKey,
    SaveButton,
  },
  data() {
    return {
      expands: [],
    }
  },
  computed: {
    compoundKeys: {
      get() {
        return this.$store.getters['definitionSettings/compoundKeys']
      },
      set(value) {
        this.$store.commit('definitionSettings/SET_COMPOUND_KEYS', value)
      },
    },
  },
  created() {
    this.setInitialExpandStatus()
  },
  methods: {
    setInitialExpandStatus() {
      this.expands = this.compoundKeys.map(() => false)
    },
    toggleExpand(index) {
      this.$set(this.expands, index, !this.expands[index])
    },
    addCompoundKey() {
      this.compoundKeys.push({
        name: '',
        keyItems: [],
      })
      this.expands.push(false)
    },
    deleteCompoundKey(index) {
      this.compoundKeys.splice(index, 1)
      this.expands.splice(index, 1)
    },
  },
}
</script>
