<template>
  <div>
    <div class="d-flex align-items-center justify-content-center mb-1">
      <h2 class="my-0 flex-grow-1">
        Options
      </h2>
      <div>
        <save-button action="definitionSettings/saveData" />
      </div>
    </div>
    <div>
      <Option
        v-for="key of editableKeys"
        :key="key"
        v-model="options[key].items"
        :title="options[key].title"
        :value-key="options[key].valueKey"
        :fields="options[key].fields"
        :sort-by="options[key].sortBy"
      />
    </div>
  </div>
</template>

<script>
import SaveButton from '@/components/UI/SaveButton.vue'
import Option from './Option.vue'

export default {
  components: {
    SaveButton,
    Option,
  },
  computed: {
    options: {
      get() {
        return this.$store.getters['definitionSettings/options']
      },
      set(value) {
        this.$store.commit('definitionSettings/SET_OPTIONS', value)
      },
    },
    editableKeys() {
      return this.$store.getters['definitionSettings/editableOptions']
    },
  },
}
</script>
